const Path = (props: any) => (
	<path strokeWidth="2" strokeLinecap="round" {...props} />
);

export const MenuToggle = ({ toggle }: any) => (
	<button onClick={toggle} tabIndex={0} aria-label="Menu de navegación">
		<svg viewBox="0 0 22 20">
			<Path
				d="M 2 2.5 L 20 2.5"
				className="top"
				variants={{
					closed: { d: 'M 2 2.5 L 20 2.5' },
					open: { d: 'M 3 16.5 L 17 2.5' }
				}}
			/>
			<Path d="M 2 9.423 L 20 9.423" opacity="1" className="middle" />
			<Path
				d="M 2 16.346 L 12 16.346"
				className="bottom"
				variants={{
					closed: { d: 'M 2 16.346 L 20 16.346' },
					open: { d: 'M 3 2.5 L 17 16.346' }
				}}
			/>
		</svg>
	</button>
);
