import Image from 'next/image';
function Logo() {
	return (
		<a className="header__logo" href="#begin" tabIndex={-1}>
			<Image
				width={100}
				height={100}
				src="/imgs/logo.png"
				alt="Logo de TM Peluquería."
				priority={true}
				loading="eager"
			/>
		</a>
	);
}

export default Logo;
