export const LINKS = [
	{
		link: '#services',
		name: 'Reserva',
		sprite: 'calendar'
	},
	{
		link: '#location',
		name: 'Ubicación',
		sprite: 'location'
	},
	{
		link: '#contact',
		name: 'Contacto',
		sprite: 'call'
	}
];
