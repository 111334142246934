'use client';
import { useEffect, useState } from 'react';

export const Switch = () => {
	const [isFirstTabActive, setIsFirstTabActive] = useState(true);
	useEffect(() => {
		if (isFirstTabActive) {
			document.getElementById('first-tab')?.classList.add('active');
			document.getElementById('second-tab')?.classList.remove('active');
		} else {
			document.getElementById('first-tab')?.classList.remove('active');
			document.getElementById('second-tab')?.classList.add('active');
		}
	}, [isFirstTabActive]);
	return (
		<>
			<button
				className={`gallery__tab-btn${isFirstTabActive ? ' active' : ''}`}
				onClick={() => setIsFirstTabActive(true)}>
				Cortes
			</button>
			<button
				className={`gallery__tab-btn${!isFirstTabActive ? ' active' : ''}`}
				onClick={() => setIsFirstTabActive(false)}>
				Local
			</button>
		</>
	);
};
