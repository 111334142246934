export const BONUS_LINKS = [
	{
		link: 'https://www.instagram.com/tm_peluqueriaybarberia/',
		name: 'Instagram'
	},
	{
		link: 'https://maps.app.goo.gl/9AG4FEK5jVUKdGjn9',
		name: 'Maps'
	},
	{
		link: 'https://wa.link/xr9qu3',
		name: 'Whatsapp'
	}
];
