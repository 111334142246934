import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/AOSComponent/index.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/components/header/Header.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/forms/contactForm/ContactForm.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/sections/gallery/Switch.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/hero.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/servicesList.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/services.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/gallery.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/features.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/slider.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/about.scss");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/styles/sections/contact.scss")