'use client';
import { useState } from 'react';
import '@/styles/forms/contactForm.scss';

export function ContactForm() {
	const [inputName, setInputName] = useState('');
	const [inputTelphone, setInputTelphone] = useState('');
	const [inputTopic, setInputTopic] = useState('');
	const [inputMessage, setInputMessage] = useState('');
	return (
		<div className="form" data-aos="fade-up">
			<form
				action="https://formsubmit.co/lucasagui2205@gmail.com"
				method="post">
				<div className="form__input-container">
					<label htmlFor="name">
						Nombre y Apellido<span>*</span>
					</label>
					<input
						id="name"
						value={inputName}
						onChange={(e) => setInputName(e.target.value)}
						name="name"
						type="text"
						required
					/>
				</div>
				<div className="form__input-container">
					<label htmlFor="phone">
						Número de Teléfono<span>*</span>
					</label>
					<input
						id="phone"
						name="phone"
						type="text"
						autoComplete="on"
						placeholder="011 15-2345-6789"
						value={inputTelphone}
						onChange={(e) => setInputTelphone(e.target.value)}
						required
					/>
				</div>
				<div className="form__input-container">
					<label htmlFor="topic">Asunto</label>
					<input
						id="topic"
						name="topic"
						type="text"
						value={inputTopic}
						onChange={(e) => setInputTopic(e.target.value)}
					/>
				</div>
				<div className="form__input-container">
					<label htmlFor="message">Mensaje</label>
					<textarea
						id="message"
						name="message"
						rows={1}
						value={inputMessage}
						onChange={(e) => setInputMessage(e.target.value)}
					/>
				</div>
				<button type="submit">
					<div className="border"></div>
					<span>Enviar mensaje</span>
				</button>
				<input type="hidden" name="_next" value="https://tmpeluqueria.shop" />
				<input type="hidden" name="_captcha" value="false" />
			</form>
		</div>
	);
}
